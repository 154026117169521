import { RouteRecordRaw } from 'vue-router';

const reportsRouter: Array<RouteRecordRaw> = [
   {
      path: 'projects-group-reports',
      name: 'projects-group-reports',
      meta: {
         title: 'Project Groups',
         permission: 'accounting_reports',
      },
      component: () =>
         import(
            '@/views/modules/accounting/views/settings/reports/projects-group-reports/base.vue'
         ),
   },
   {
      path: 'trial-balance-report',
      name: 'trial-balance-report',
      meta: {
         title: 'Trial balance balances',
         permission: 'accounting_reports',
      },
      component: () =>
         import('@/views/modules/accounting/views/settings/reports/trial-balance-report/base.vue'),
   },
   {
      path: 'general-journal-report',
      name: 'general-journal-report',
      meta: {
         title: 'General Journal',
         permission: 'accounting_reports',
      },
      component: () =>
         import(
            '@/views/modules/accounting/views/settings/reports/general-journal-report/base.vue'
         ),
   },
   {
      path: 'detailed-journal-report',
      name: 'detailed-journal-report',
      meta: {
         title: 'Detailed Journal',
         permission: 'accounting_reports',
      },
      component: () =>
         import(
            '@/views/modules/accounting/views/settings/reports/general-journal-report/detailed.vue'
         ),
   },
   {
      path: 'accounts-group-report',
      name: 'accounts-group-report',
      meta: {
         title: 'Accounts Group',
         permission: 'accounting_reports',
      },
      component: () =>
         import('@/views/modules/accounting/views/settings/reports/accounts-group-report/base.vue'),
   },
   {
      path: 'ledger-report',
      name: 'ledger-report',
      meta: {
         title: 'Ledger',
         permission: 'accounting_reports',
      },
      component: () =>
         import('@/views/modules/accounting/views/settings/reports/ledger-report/base.vue'),
   },
   {
      path: 'detailed-ledger-report',
      name: 'detailed-ledger-report',
      meta: {
         title: 'Detailed Ledger',
         permission: 'accounting_reports',
      },
      component: () =>
         import('@/views/modules/accounting/views/settings/reports/ledger-report/detailed.vue'),
   },
   {
      path: 'cost-center-groups-report',
      name: 'cost-center-groups-report',
      meta: {
         title: 'Cost Center Groups',
         permission: 'accounting_reports',
      },
      component: () =>
         import(
            '@/views/modules/accounting/views/settings/reports/cost-center-group-report/base.vue'
         ),
   },
   {
      path: 'cost-center-detailed-report',
      name: 'cost-center-detailed-report',
      meta: {
         title: 'Detail Cost Centers',
         permission: 'accounting_reports',
      },
      component: () =>
         import(
            '@/views/modules/accounting/views/settings/reports/cost-center/detailed-report.vue'
         ),
   },
   {
      path: 'cost-center-total-report',
      name: 'cost-center-total-report',
      meta: {
         title: 'Total Cost Centers',
         permission: 'accounting_reports',
      },
      component: () =>
         import('@/views/modules/accounting/views/settings/reports/cost-center/total-report.vue'),
   },
   {
      path: 'cost-center-analyze-report',
      name: 'cost-center-analyze-report',
      meta: {
         title: 'Analyze Cost Centers',
         permission: 'accounting_reports',
      },
      component: () =>
         import('@/views/modules/accounting/views/settings/reports/cost-center/analyze-report.vue'),
   },
   {
      path: 'projects-detailed-report',
      name: 'projects-detailed-report',
      meta: {
         title: 'Detail Projects',
         permission: 'accounting_reports',
      },
      component: () =>
         import('@/views/modules/accounting/views/settings/reports/projects/detailed-report.vue'),
   },
   {
      path: 'projects-total-report',
      name: 'projects-total-report',
      meta: {
         title: 'Total Projects',
         permission: 'accounting_reports',
      },
      component: () =>
         import('@/views/modules/accounting/views/settings/reports/projects/total-report.vue'),
   },
   {
      path: 'projects-analyze-report',
      name: 'projects-analyze-report',
      meta: {
         title: 'Analyze Projects',
         permission: 'accounting_reports',
      },
      component: () =>
         import('@/views/modules/accounting/views/settings/reports/projects/analyze-report.vue'),
   },
   {
      path: 'trial-balance-by-total-report',
      name: 'trial-balance-by-total-report',
      meta: {
         title: 'Trial balance totals',
         permission: 'accounting_reports',
      },
      component: () =>
         import(
            '@/views/modules/accounting/views/settings/reports/trial-balance-total-report/base.vue'
         ),
   },
   {
      path: 'budget-report',
      name: 'budget-report',
      meta: {
         title: 'Balance Sheet',
         permission: 'accounting_reports',
      },
      component: () => import('@/views/modules/accounting/views/settings/reports/budget/index.vue'),
   },
   {
      path: 'income-statement-report',
      name: 'income-statement-report',
      meta: {
         title: 'Income Statement',
         permission: 'accounting_reports',
      },
      component: () =>
         import('@/views/modules/accounting/views/settings/reports/income-statement/index.vue'),
   },
   {
      path: 'analyze-trial-balance-by-total-report',
      name: 'analyze-trial-balance-by-total-report',
      meta: {
         title: 'analyzeTrialBalanceByTotalReport',
         permission: 'accounting_reports',
      },
      component: () =>
         import(
            '@/views/modules/accounting/views/settings/reports/analyze-trial-balance-by-total-report/base.vue'
         ),
   },
   {
      path: 'receipts-within-period',
      name: 'receipts-within-period',
      meta: {
         title: 'Receipts Within Period',
         permission: 'accounting_reports',
      },
      component: () =>
         import(
            '@/views/modules/accounting/views/settings/reports/receipts-within-period/base.vue'
         ),
   },
   {
      path: 'payments-within-period',
      name: 'payments-within-period',
      meta: {
         title: 'Payments Within Period',
         permission: 'accounting_reports',
      },
      component: () =>
         import(
            '@/views/modules/accounting/views/settings/reports/payments-within-period/base.vue'
         ),
   },
];

export default reportsRouter;
