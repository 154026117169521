import JournalEntriesModule from "./modules/JournalEntriesModule";
import ReceiptModule from "./modules/ReceiptModule";
import VoucherModule from "./modules/VoucherModule.js";
import AccountingSettingModule from "./modules/AccountingSettingModule.js";
import JournalEntryDailyModule from "./modules/JournalEntryDailyModule.js";
import JournalEntryTemplateModule from "./modules/JournalEntryTemplateModule.js";
import JournalEntryPostingsModule from "./modules/JournalEntryPostingsModule.js";
import CostCenterReportModule from "./modules/report/CostCenterReportModule.js";
import PojectsGroupReportModule from "./modules/report/PojectsGroupReportModule.js";
import ProjectsReportModule from "./modules/report/ProjectsReportModule.js";
import TrialBalanceReportModule from "./modules/report/TrialBalanceReportModule.js";
import GeneralJournalReportModule from "./modules/report/GeneralJournalReportModule.js";
import AccountsGroupReportModule from "./modules/report/AccountsGroupReportModule.js";
import LedgerReportModule from "./modules/report/LedgerReportModule.js";
import BudgetReportModule from "./modules/report/BudgetReportModule.js";
import TrialBalanceByTotalReportModule from "./modules/report/TrialBalanceByTotalReportModule.js";
import AnalyzeTrialBalanceByTotalReportModule from "./modules/report/AnalyzeTrialBalanceByTotalReportModule.js";
import AccountingDashboardModule from "./modules/AccountingDashboardModule.js";
import RecycleTheFiscalYearModule from "./modules/RecycleTheFiscalYearModule.js";
import CloseFiscalYearModule from "./modules/CloseFiscalYearModule.js";

const accountingStore = {
  ReceiptModule,
  JournalEntriesModule,
  VoucherModule,
  AccountingSettingModule,
  JournalEntryDailyModule,
  JournalEntryPostingsModule,
  JournalEntryTemplateModule,
  CostCenterReportModule,
  PojectsGroupReportModule,
  ProjectsReportModule,
  GeneralJournalReportModule,
  AccountsGroupReportModule,
  LedgerReportModule,
  TrialBalanceReportModule,
  BudgetReportModule,
  TrialBalanceByTotalReportModule,
  AnalyzeTrialBalanceByTotalReportModule,
  AccountingDashboardModule,
  RecycleTheFiscalYearModule,
  CloseFiscalYearModule,
};

export default accountingStore;
